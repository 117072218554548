import { Environment } from './environment.interface';

export const environment: Environment = {
    production: true,
    environment: 'dev',
    login_url: `http://openam.dev-apps.enelx.net/openam/XUI/`,
    global_nav_url: 'https://global-nav-v2.dev-svcs.enernoc.net/api/v2',
    app_base: `dev-apps.enelx.net`,
    open_am_cookie_domain: 'enelx.net',
	default_brand: 'enelx',
    default_locale: 'en-us'
};

