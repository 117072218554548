import { parseExpression } from 'cron-parser';
import { duration } from 'moment';
import { HttpParams } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '@components';
import { ComRegistration, Point, Report, VEN } from '@models';
import { GlobalAlertService, ComProductService, ComRegistrationService, PointService, ReportService, VenService } from '@services';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GRANULARITIES } from '../../constants';

interface ReportLink {
    id: string;
    display_labels: {[locale: string]: string};
    value: string;
    rID?: string;
    resource_id?: string;
    group_id?: string;
    granularity: number;
    report_back_duration: number;
}

@Component({
    selector: 'report-edit-view',
    templateUrl: './index.html',
    styleUrls: ['./style.scss'],
})
export class ReportEditViewComponent implements OnInit {
    BAD_REQUEST = 'Oops, something went wrong';
    REPORT_SUCCESS = '';
    TYPES = [
        {label: 'telemetry_usage', value: 'TELEMETRY_USAGE', disabled: false},
        {label: 'offers', value: 'x-OFFERS', disabled: false},
    ];
    GRANULARITIES = GRANULARITIES;

    constructor(
        public dialog: MatDialog,
        private translateService: TranslateService,
        private ngZone: NgZone,
        private route: ActivatedRoute,
        private router: Router,
        private comProductService: ComProductService,
        private comRegistrationService: ComRegistrationService,
        private pointService: PointService,
        private reportService: ReportService,
        private venService: VenService,
        private messageService: GlobalAlertService,
    ) {
        this.BAD_REQUEST = this.translateService.instant('notification.bad_request');
        this.REPORT_SUCCESS = this.translateService.instant('reports.edit.form.notification.success');
    }

    ven: VEN;
    originalReportId: number;
    submitting = false;
    report: Report;
    reportForm: UntypedFormGroup;
    links: ReportLink[] = [];
    productSchedules: {[id: string]: {granularity: number, report_back_duration: number}} = {};
    subscriptionsFetched: number;
    subscriptionsTotal: number;
    previousReports: Report[];
    descriptionCols = ['rID', 'readingType', 'reportDataSource', 'link'];
    warning: string;

    ngOnInit(): void {
        // Get and validate path parameters
        const { venId, reportId } = this.route.snapshot.params;
        if (!venId || !venId.match(/\d+/)) {
            this.router.navigate(['/']);
            return;
        }

        Promise.all([
            this.reportService.getById(reportId)
                .toPromise()
                .catch(() => this.messageService.setError(this.BAD_REQUEST)),
            this.venService.getById(venId).toPromise(),
            this.reportService.getPage(new HttpParams({fromObject: {per_page: 1000, ven_id: venId}})),
        ]).then(([report, ven, allVenReports]) => {
            this.report = report as Report;
            if (this.report.requested_dttm) {
                this.router.navigate(['vens', venId]);
                return;
            }

            this.ven = ven;
            this.previousReports = allVenReports.data.filter(r => (
                r.id !== this.report.id &&
                !r.archived &&
                !r.canceled_dttm &&
                r.specifier_id === this.report.specifier_id
            ));
            this.fetchAllLinks();
            this.setupForm();
        });
    }

    setupForm() {
        this.reportForm = new UntypedFormGroup({
            granularity: new UntypedFormControl(this.report.granularity, Validators.required),
            report_back_duration: new UntypedFormControl(this.report.report_back_duration, Validators.required),
            start_dttm: new UntypedFormControl(this.report.start_dttm, Validators.required),
            end_dttm: new UntypedFormControl(this.report.end_dttm, Validators.required),
        });
    }

    handleEventCancel() {
        this.router.navigate(['/vens', this.ven.id]);
    }

    selectLink(): void {
        this.inferGranularity();
    }

    selectableLinks(desc: any): ReportLink[] {
        const alreadyLinked = new Set(Object.values(this.report.id_map));
        const notSelected = this.links.filter(link => (
            !alreadyLinked.has(link.value) || // Not yet selected for another rID
            this.report.id_map[desc.rID] === link.value // Or has been selected for this rID
        ))
        if (desc.reportDataSource?.resourceID) {
            return notSelected.filter(p => p.resource_id === desc.reportDataSource.resourceID);
        } else if (desc.reportDataSource?.groupID) {
            return notSelected.filter(p => p.group_id === desc.reportDataSource.groupID);
        }
        return notSelected;
    }

    setReady(ev) {
        this.report.requested_dttm = ev.checked ? new Date().toISOString() : null;
        this.report.report_request_id = this.report.report_request_id || crypto.randomUUID();
    }

    allowRequestedDttm(): boolean {
        // Drop the "undefined" values from id_map
        Object.keys(this.report.id_map)
            .filter(k => !this.report.id_map[k])
            .forEach(k => Reflect.deleteProperty(this.report.id_map, k));

        return Boolean(this.reportForm.get('granularity').value) &&
               Object.keys(this.report.id_map).length === this.report.descriptions.length;
    }

    inferGranularity() {
        const granularities: Set<number> = new Set();
        const rbds = [];
        Object.keys(this.report.id_map).map((rID) => {
            const link = this.links.find(l => l.value === this.report.id_map[rID]);
            if (link) {
                granularities.add(link.granularity);
                rbds.push(link.report_back_duration);
            }
        });
        let rbd = Math.min(...rbds);
        if (rbd === Infinity) rbd = 0;

        if (granularities.size === 0) {
            this.warning = null;
            this.reportForm.get('granularity').setValue(0);
            this.reportForm.get('report_back_duration').setValue(0);
        } else if (granularities.size === 1) {
            this.warning = null;
            const gran = Array.from(granularities)[0];
            this.reportForm.get('granularity').setValue(gran);
            this.reportForm.get('report_back_duration').setValue(rbd);
        } else {
            const type = this.report.type === 'TELEMETRY_USAGE' ? 'points' : 'registrations';
            this.warning = `reports.edit.warning.multiple_grans.${type}`;
            this.reportForm.get('granularity').setValue(0);
            this.reportForm.get('report_back_duration').setValue(0);
        }
    }

    fetchAllLinks() {
        this.links = [];
        const subscriptions = [...this.ven.groups, ...this.ven.resources];
        this.subscriptionsFetched = 0;
        this.subscriptionsTotal = subscriptions.length;

        this.fetchLinks(subscriptions);
    }

    fetchLinks(subscriptions: any[]) {
        if (subscriptions.length === 0) {
            this.subscriptionsFetched = null;
            this.subscriptionsTotal = null;
            if (this.links.length === 0 && this.report.type === 'TELEMETRY_USAGE') {
                this.warning = 'reports.edit.warning.no_points_found';
            }
            if (this.links.length === 0 && this.report.type === 'x-OFFERS') {
                this.warning = 'reports.edit.warning.no_registrations_found';
            }
            this.autoSelect();
            return;
        }

        this.subscriptionsFetched = this.subscriptionsTotal - subscriptions.length;

        if (this.report.type === 'TELEMETRY_USAGE') {
            this.fetchPoints(subscriptions);
        } else {
            this.fetchRegistrations(subscriptions);
        }
    }

    fetchPoints(subscriptions) {
        const sub = subscriptions.pop();
        let query: any = {};
        if (sub.group_id) {
            query.product_id = sub.product_id;
            query.portfolio_id = sub.portfolio_id;
        } else {
            query.registration_id = sub.registration_id;
        }

        this.pointService
            .getList(query)
            .toPromise()
            .then(res => {
                // Only show points that match the supported granularities
                const supported = new Set(this.GRANULARITIES.map(g => g.value).filter(v => Boolean(v)));
                const seen = new Set(this.links.map(p => p.id));
                const toAdd: ReportLink[] = ((res || []) as Point[])
                    .filter(p => (
                        supported.has(p.reporting_interval_ms) &&
                        Boolean(p.alternate_ids?.SOURCE_ID) &&
                        !seen.has(p.id)
                    ))
                    .map(p => ({
                        id: p.id,
                        display_labels: p.display_labels,
                        value: p.alternate_ids.SOURCE_ID,
                        group_id: sub.group_id,
                        resource_id: sub.resource_id,
                        granularity: p.reporting_interval_ms,
                        report_back_duration: p.reporting_interval_ms,
                    }));

                this.links = [...this.links, ...toAdd];
                this.fetchLinks(subscriptions);
            })
            .catch(err => this.fetchLinks(subscriptions));
    }

    fetchRegistrations(subscriptions) {
        const sub = subscriptions.pop();

        let prm: Promise<ComRegistration[]>;
        if (sub.group_id) {
            prm = this.comRegistrationService.getRegistrations('PORTFOLIO', sub.portfolio_id)
                .then(resp => resp.data.filter(r => r.product_id === sub.product_id));
        } else {
            prm = this.comRegistrationService.getRegistrations('REGISTRATION', sub.registration_id)
                .then(resp => resp.data);
        }
        prm.then((regs) => {
            const pIDsToFetch = regs.map(r => r.product_id).filter(pid => !this.productSchedules[pid]);
            const uniqueProductIDs = Array.from(new Set(pIDsToFetch));
            const promises = uniqueProductIDs.map(pid => this.comProductService.getProductById(pid));
            Promise.all(promises).then((productResponses) => {
                for (const resp of productResponses) {
                    const granularity = duration({
                        [resp.data.schedule.offer_frequency_unit.toLowerCase()]: resp.data.schedule.offer_frequency,
                    }).as('milliseconds');

                    let report_back_duration = 0;
                    try {
                        const ivl = parseExpression(
                            resp.data.schedule.rule,
                            {currentDate: new Date(), tz: 'UTC'}
                        );
                        const n1 = ivl.next().toDate().valueOf();
                        const n2 = ivl.next().toDate().valueOf();
                        report_back_duration = n2 - n1;
                        // Adjust for DST
                        if (report_back_duration > 22 * 60 * 60 * 1000) {
                            const DAY = 24 * 60 * 60 * 1000;
                            report_back_duration = Math.round(report_back_duration / DAY) * DAY;
                        }
                    } catch (e) {
                        console.log(`error parsing product offer schedule: "${resp.data?.schedule?.rule}" "${e.message}"`);
                    }
                    if (report_back_duration === 0 || !GRANULARITIES.find(g => g.value === report_back_duration)) {
                        report_back_duration = GRANULARITIES.find(g => g.label === 'oneday').value;
                    }
                    this.productSchedules[resp.data.id] = {granularity, report_back_duration};
                }

                const seen = new Set(this.links.map(p => p.id));
                const toAdd: ReportLink[] = (regs || [])
                    .filter(r => !seen.has(r.id))
                    .map(r => ({
                        id: r.id,
                        display_labels: r.display_labels,
                        value: r.id,
                        group_id: sub.group_id,
                        resource_id: sub.resource_id,
                        granularity: this.productSchedules[r.product_id].granularity || 0,
                        report_back_duration: this.productSchedules[r.product_id].report_back_duration || 0,
                    }));

                this.links = [...this.links, ...toAdd];
                this.fetchLinks(subscriptions);
            });
        })
        .catch(err => this.fetchLinks(subscriptions));
    }

    /*
     * Try to automatically associate links (points/regs) to rIDs through the
     * description reportDataSource, if done by resourceID.
     */
    autoSelect() {
        for (const desc of this.report.descriptions) {
            if (this.report.id_map[desc.rID] || !desc.reportDataSource?.resourceID) {
                continue;
            }
            const resourceLinks = this.selectableLinks(desc);
            if (resourceLinks.length === 1) {
                this.report.id_map[desc.rID] = resourceLinks[0].id;
            }
        }
        this.inferGranularity();
    }

    openCancelOrAcceptDialog() {
        let msg = `reports.edit.${this.report.requested_dttm ? 'publish_' : '' }verification`;
        this.dialog.open(DialogComponent, {
          width: '531px',
          data: {
            title: this.translateService.instant(msg),
            secondButtonText: this.translateService.instant('actions.accept'),
            firstButtonText: this.translateService.instant('actions.cancel'),
            secondButtonCallback: () => this.save(),
            firstButtonCallback: () => this.dialog.closeAll(),
          },
        });
    }

    save() {
        this.submitting = true;

        // Assemble payload
        const payload = {
            ven_id: this.ven.id,
            id_map: this.report.id_map,
            report_request_id: this.report.report_request_id,
            requested_dttm: this.report.requested_dttm,
            ...this.reportForm.getRawValue()
        };

        // If there are existing reports for this same ven & specifier_id, cancel them
        let prefixCall: Promise<any>;
        if (payload.requested_dttm && this.previousReports.length) {
            prefixCall = Promise.all(this.previousReports.map(pr => (
                this.reportService
                    .update(pr.id, {canceled_dttm: new Date().toISOString()})
                    .toPromise()
            )));
        } else {
            prefixCall = Promise.resolve();
        }

        prefixCall.then(() => {
            return this.reportService.update(this.report.id, payload).toPromise();
        }).then((report) => {
            this.ngZone.run(() => {
              this.messageService.setSuccess(this.REPORT_SUCCESS);
              this.router.navigateByUrl(`vens/${this.ven.id}`);
            });
        })
        .catch(() => {
            this.messageService.setError(this.BAD_REQUEST);
        })
        .finally(() => {
            this.submitting = false;
            this.dialog.closeAll();
        });
    }
}
